﻿import { defineComponent } from 'vue';

import { product_detail } from '../../../objects/v1_3/product/product/product_detail';
import { room_passengers } from '../../../objects/front_end/room/room_passengers';
import { room_passengers_type } from '../../../objects/front_end/room/room_passengers_type';

import { product_detail_seller_information } from '../../../objects/v1_3/product/product/product_detail_seller_information';
import { product_detail_seller_information_room } from '../../../objects/v1_3/product/product/product_detail_seller_information_room';

import { Booking } from "../../../services/booking";
import { Room } from "../../../services/room";
import { Product } from '../../../services/product';

import { Currency_Service } from "../../../../system/services/currency";
import { room } from '../../../objects/v1_2/Booking/Room';
import { stringifyQuery } from 'vue-router';

const Book = new Booking()
const Prod = new Product();
const Service_Room = new Room();
const Currency_Services = new Currency_Service();

export default defineComponent({
    name: "sales_group_room_type",
    props: {
        tour_id: String,
        year: Number,
        month: Number,
        trip_index: Number
    },
    data() {
        return {
            product: new product_detail(),
            room_selected: null as string | null,
            currentRoomInfo: null as string | null,
            from_Price: 0 as number
        }
    },
    methods: {
        get_Rooms(): product_detail_seller_information_room[] {
            if (this.product == undefined) {
                return [];
            }

            if (this.product.seller_information == undefined) {
                return [];
            }

            return this.product.seller_information.rooms;
        },
        get_Room_List(): room_passengers_type[] {
            return Service_Room.get_Room_List(this.product, this.$store);
        },
        async get_Product() : Promise<void> {
            this.product = await Prod.Get_Details(this.tour_id ?? "", this.$store);
        },
        async select_room_type(room: room_passengers_type) {
            this.room_selected = room.name;

            this.$store.commit("Initialise_Booking_Room_Type", { Trip_Index: this.get_Trip_Index(), Room_Type: room });
            await Book.Setup_Booking(this.$store);
        },
        get_Minimum_Price(): number {
            return Service_Room.get_Minimum_Price(this.product, this.year ?? 0, this.month ?? 0, this.$store);
        },
        get_Total(): number {
            return Book.get_total_price(this.$store.getters.get_Booking, this.$store);
        },
        get_Price(room: room_passengers_type) {
            let Price: number = this.get_Sales_Price(room);
            let Min_Price: number = this.get_Minimum_Price();

            if(room.name?.toLowerCase() == "1 single room" && this.get_Total() != 0){
                if(this.from_Price == 0){
                    this.from_Price = this.get_Total();
                }
                return "+ " + Currency_Services.Format_Currency(Price - this.from_Price, this.$store.getters.get_Currency);
            }

            if (Min_Price == Price) {
                return "";
            }
            
            return "+ " + Currency_Services.Format_Currency(Price - Min_Price, this.$store.getters.get_Currency);
        },
        get_Sales_Price(room: room_passengers_type): number {
            return Service_Room.get_Sales_Price(this.product, room, this.year ?? 0, this.month ?? 0, this.$store);
        },
        get_ID(room: product_detail_seller_information_room): string {
            return this.tour_id + "-" + room.name.replace(" ", "");
        },
        get_Room_Capacity(room: room_passengers_type): string {
            return Service_Room.get_Room_Capacity(room, this.$store) + "--" + String(Service_Room.get_Room_Capacity_Available(room, this.$store));
        },
        get_Room_Available(room: room_passengers_type): boolean {
            let Capacity_Available = Service_Room.get_Room_Capacity_Available(room, this.$store);
            if(room.name?.toLowerCase() == this.room_selected?.toLowerCase() && !Capacity_Available){
                this.clearRoomSelected();
            }
            return Capacity_Available;
        },
        clearRoomSelected(){
            let null_room: room_passengers_type = new room_passengers_type();
            this.select_room_type(null_room);
        },
        get_Trip_Index(): number {
            return this.trip_index ?? 0;
        },
        get_room_description(room: string): string {

            let output: string ="";
            if(room.includes("Twin Room")){
                output+= "Twin Room: 1 room with 2 single beds. Suitable for 2 people, one bed each.\n";
            }
            if(room.includes("Double Room")){
                output+= "Double Room: 1 room with 1 double bed. Suitable for 2 people to share\n";
            }
            if(room.includes("1 Single")){
                output+= "Single Room: 1 room with 1 single bed. Suitable for 1 person. There will be a single occupancy supplement\n";
            }
            if(room.includes("2 Single")){
                output+= "2 Single Rooms: 2 rooms with 1 single bed. Suitable for 2 people.\n";
            }
            if(room.includes("Family 3 Room")){
                output+= "Family 3 Room: 1 room with 1 double bed and 1 single bed. Suitable for 3 people.\n";
            }
            if(room.includes("Family 4 Room")){
                output+= "Family 4 Room: 1 room with 1 double bed and 2 single beds. Suitable for 4 people.\n";
            }
            return output;
        },
        setShowInfo(room: string){
            if(this.currentRoomInfo == room){
                this.currentRoomInfo = null;
            }
            else{
                this.currentRoomInfo = room;
            }
        }
    },
    mounted() {
        this.get_Product()
    }
})