﻿<template>

    <div class="card-inner">
        <a class="cover" @click="tour_selected()">
            <div class="AccomIncluded" v-if="durationInDays().toUpperCase().includes('DAYS') && !isAAT()">
                <span class="accommodation">Accommodation</span>
                <span class="included">included</span>
            </div>
            <span v-if="isAAT() && isAgent_Login()" class="mapping_code">{{Mapping_Codes()}}</span>
            <span v-if="(!isAAT() && isAgent_Login()) || localUrl()"  class="mapping_code">{{tour_details?.tour_id}}</span>
            <span v-if="isAAT()" class="days">{{duration()}}</span>
            <span v-if="!isAAT()" class="days">{{durationInDays()}}</span>
            <span class="price">{{price()}} <small class="currency">{{currency()}}</small></span>
            <v-lazy-image class="image" :src="image()" :alt="imageAltText()"/>
        </a>
        <div class="content">
            <h3><a @click="tour_selected()" class="cursor-pointer cardTitle " :data="tour_details?.tour_id">{{tour_details.name}}</a></h3>
            <p>{{tour_details.start_location.city ?? tour_details.start_location.title}} departure</p>
            <p class="text-sm">{{Format_Description(display_description())}}</p>
        </div>

        <div>
            <p v-if="!InCart" class="action pt-4">
                <a @click="tour_see_details(tour_details.tour_id)" target="_blank" class="more cursor-pointer">See tour details</a>
                <a v-if="bookable()" class="button button-standard xsmall" @click="tour_selected()">Book Now</a>
                <a v-else class="button button-standard xsmall">Loading</a>
            </p>
            <p v-else class="action pt-4">
                <a @click="tour_see_details(tour_details.tour_id)" target="_blank" class="more cursor-pointer">See tour details</a>
                <router-link to="/cart" tag="button">
                    <a type="button" class="button border-grey-default bg-form text-base text-action xsmall">Added to Cart</a>
                </router-link>
            </p>
        </div>

    </div>
    <div class="slideout" :hidden="isHidden" 
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64"
         style="z-index: 201 !important;">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isHidden = true"></button>

            <div class="slideout-content">
                <itinerary :tour_id="tour_details?.tour_id" :banner="true" ref="itinerary" v-if="!isHidden"></itinerary>
            </div>

        </div>

        <div class="slideout-bg" @click="isHidden = true"></div>

    </div>

    <div class="slideout" v-if="loadingOpenTour" 
         x-transition:enter="transition ease-out duration-500 -mr-64"
         x-transition:enter-start=""
         x-transition:enter-end="transform -translate-x-64"
         x-transition:leave="transform ease-out duration-75"
         x-transition:leave-start=""
         x-transition:leave-end="transform translate-x-64"
         style="z-index: 201 !important;">
        <div class="slideout-inner">
            <button class="close no-bg" @click="isHidden = true"></button>

            <div class="pb-60" >
                <div class="w-2/3 float-left  px-5">
                    <h3 class="tourTitle">{{tour_details?.name}}</h3>
                    <p class="mt-0">{{tour_details?.description.replace(/<\/?[^>]+(>|$)/g, "")}}</p>
                </div>  
                <div class="w-1/3 float-left py-8 px-5">
                    <img class="image rounded" :src="imageSmall()" :alt="imageAltText()">
                </div>
            </div>

            <button type="button" class="button button-pink-book full mx-30 my-5p-10">
                <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                    <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                </svg>
                Loading Tour
            </button>
        </div>

    </div>

</template>

<script lang="ts" src="./tour_card.ts"></script>