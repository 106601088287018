﻿<template>
    <div>
        <!--dektop view-->
        <div class="pb-60 tourHeading" :class="{'hidden': isMobile()}">
            <div class="w-2/3 float-left  px-5">
                <h3 class="tourTitle">{{product?.seller_information?.sales_group?.group_name}}</h3>
                <p class="mt-0">{{product?.marketing_information?.description.replace(/<\/?[^>]+(>|$)/g, "")}}</p>
            </div>  
            <div class="w-1/3 float-left py-8 px-5">
                <img class="image rounded" :src="product?.marketing_information?.media[0]?.assets[0]?.asset_url + '?anchor=center&mode=crop&width=300&height=200'" :alt="imageAltText()">
            </div>
        </div>

        <!-- Seems to reset view on some large screens -->
        <div class="pb-6" :class="{'hidden': isMobile()}"></div>

        <!--mobile and tablet view-->
        <div class="pb-6" :class="{'hidden': !isMobile()}">
            <div>
                <h3 class="tourTitle text-center">{{product?.seller_information?.sales_group?.group_name}}</h3>
                <div class="flex pb-3 ">
                    <img class="mx-auto image rounded w-60" :src="product?.marketing_information?.media[0]?.assets[0]?.asset_url + '?anchor=center&mode=crop&width=300&height=200'" :alt="imageAltText()">
                </div>
                <p class="mt-0">{{product?.marketing_information?.description}}</p>
            </div>
        </div>
        <!--booking notes pop-up-->
        <button v-if="!isAgent_Login() && GetBookingNotes() != ''" class="px-5 border-grey-default bg-form border-blank button text-black full" :disabled="true">{{GetBookingNotes()}}</button>
    </div>
</template>

<script lang="ts" src="./tour_heading.ts"></script>