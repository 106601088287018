﻿import { defineComponent } from 'vue';

import trip_summary from "../common/trip_summary.vue";
import itinerary_breakdown from "../common/itinerary_breakdown.vue";

import { booking_group } from "../../../objects/v1_2/Booking/Booking_Group"
import { age_cat } from "../../../objects/v1_3/web_engine/age_cat"

import { Booking } from "../../../services/booking";
import { Product } from "../../../services/product";
import { Web_Engine } from "../../../services/web_engine"

import { Currency_Service } from "../../../../system/services/currency";
import { stringifyQuery } from "vue-router";
import { Room } from "../../../services/room";

const Book = new Booking();
const Prod = new Product();
const Web = new Web_Engine();
const Currency_Services = new Currency_Service();
const Service_Room = new Room();

class GooglePassengerDetail {
    public age_cat: string = "";
    public count: number = 0;
    public price: number = 0;
}

export default defineComponent({
    name: "basket",
    emits: [
        "Add_To_Cart"
    ],
    components: {
        trip_summary,
        itinerary_breakdown
    },
    props: {
        trip_index: Number
    },
    data() {
        return {
            age_cat: new age_cat(),
            passenger: 0,
            open: false
        }
    },
    methods: {
        async download_passenger() {
            this.age_cat = await Web.Get_Passengers();

            this.age_cat.categories = this.age_cat.categories.sort((first, second) => {
                return 0 - (first.name > second.name ? -1 : 1)
            });
        },
        get_Basket(): booking_group {
            return this.$store.getters.get_Basket;
        },
        isBookable(): boolean {

            let Quote_Basket: booking_group = this.get_Basket();
            let productDetails = Prod.Get_Details_Sync(this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id, this.$store);
            let roomlength = 0;

            if(Prod.getRoundedDays(productDetails.operational_information?.duration) > 1){
                let productDetails = Prod.Get_Details_Sync(this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id, this.$store);
                if(Quote_Basket.trips[this.get_Trip_Index()]?.rooms != null){
                    roomlength = Quote_Basket.trips[this.get_Trip_Index()]?.rooms.length;
                }
                if(Service_Room.get_Room_List(productDetails, this.$store).length == 0){
                    roomlength = 1;
                }
            }
            else{
                roomlength = 1;
            }
            
            if (roomlength > 0 && Quote_Basket?.passengers?.length > 0 && Quote_Basket?.trips[this.get_Trip_Index()]?.tour_id != null && Quote_Basket?.trips[this.get_Trip_Index()]?.departure_date != null && Book.get_total_price(this.$store.getters.get_Booking, this.$store) > 0) {
                return true;
            }

            return false;
        },
        async Booking_Trip() {
            let booking_info = await Book.Book_Trip(this.$store).then(response => { return response.json(); });

            if (booking_info.group_booking_id > 0) {
                this.$store.commit("Setup_Booking", { Booking_Details: booking_info });
                this.$router.push("/booking/" + booking_info.group_booking_id);
            } else {
                this.$store.getters.get_Basket.trips.forEach(trip => {
                    if(trip.rooms == null || trip.rooms.length == 0 || trip.rooms == undefined){
                        booking_info.booking_error = "Error: No rooms available or incorrectly loaded rooms."
                    }
                });
                alert("Basket " + booking_info.booking_error);
            }
        },
        Add_To_Cart() {
            if(this.get_passangers().toUpperCase().includes("CHILD")){
                if(!this.get_passangers().includes("Adult") && !this.get_passangers().includes("Senior") && !this.get_passangers().includes("Student")){
                    alert("You need to have at least one 18+ member in the group");
                    return;
                }
            }
            if(Book.get_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency <1){
                alert("Sorry, an error has occred, trip failed to add");
                return;
            }

            this.$emit("Add_To_Cart");
            this.gtag_add_to_cart();
        },
        get_passangers(): string {
            return Book.Passenger_Details(false, this.age_cat, this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id, this.$store.getters.get_Booking);
        },
        gtag_get_item(index: number, paxType: string, paxCount: number, paxPrice: number) {

            let tour_id = this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id;
            let lengthCategory = "1 day tour";
            if(parseInt(Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store)).replace(" days", "").replace(" day", "")) > 1){
                lengthCategory = "Multi day tour";
            }

            var item = {
                item_id: tour_id,
                item_name: this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_name,
                affiliation: import.meta.env.VITE_DEFAULT_TAB_TEXT + " Booking Engine",
                coupon: this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.promo_code,
                index: index,
                item_brand: import.meta.env.VITE_DEFAULT_TAB_TEXT,
                item_category: Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store)),
                item_category2: lengthCategory,
                item_category3: Prod.product_start_location(Prod.Get_Details_Sync(tour_id, this.$store)),
                item_category4: paxType,
                item_list_name: lengthCategory,
                price: paxPrice,
                quantity: paxCount
            };

            return item;
        },
        
        gtag_add_to_cart() {

            var allItems: [] = [];
            var index = 0;
            var totalAmount = 0;
            var GooglePassengerDetails: GooglePassengerDetail[] = [];


            var trip = this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]

            trip.passengers.forEach(pax => {
                let passengerAge = Book.get_Passenger_Age_Cat(pax.passenger_identifier, this.$store.getters.get_Booking);
                totalAmount += pax.price;

                if(GooglePassengerDetails.findIndex(f => f.age_cat == passengerAge) == -1){
                    GooglePassengerDetails.push({age_cat: passengerAge, count: 1, price: pax.price});
                }
                else{
                    let index_location = GooglePassengerDetails.findIndex(f => f.age_cat == passengerAge);
                    GooglePassengerDetails[index_location].count++;
                    GooglePassengerDetails[index_location].price = pax.price;
                }

            });

            GooglePassengerDetails.forEach(pax => {
                allItems.push(this.gtag_get_item(index, pax.age_cat, pax.count, pax.price));
                index++;
            });


            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: this.$store.getters.get_Currency.currency_info.iso_currency_code,
                    value: totalAmount,
                    items: allItems
                }
            });
        },
        get_Total(): string {
            return Currency_Services.Format_Currency(Book.get_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Curreny_Code() {
            return this.$store.getters.get_Currency.currency_info.iso_currency_code;
        },
        get_Booking_Summary() {
            let Output: string = "";

            let productDetails = Prod.Get_Details_Sync(this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id, this.$store);

            Output += Prod.product_duration_information_rounded(productDetails) + " " + Prod.product_overnight_rounded_duration(productDetails.operational_information.duration);
            Output += " for " + Book.Passenger_Details(false, this.age_cat, this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.tour_id, this.$store.getters.get_Booking);

            if (this.$store.getters.get_Booking?.trips[this.get_Trip_Index()]?.reservation_rooms?.length > 0) {
                Output += " incl. accommodation";
            }

            return Output + ".";
        },
        get_Trip_Index(): number {
            return this.trip_index ?? 0;
        }
    },
    mounted() {
        this.download_passenger();
    }
})