﻿import { defineComponent } from 'vue';

import header_menu from "../header/menu.vue"
import hero from "../header/hero.vue"
import footer_item from "../base/common/footer.vue"

import basket from "../base/common/basket.vue"

import documentation from "../base/5_confirmation/documentation.vue"
import trip_summary from "../base/5_confirmation/summary.vue"
import { client_booking } from '../../objects/v1_2/Booking_Details/client_booking';

import { Booking } from "../../services/booking";

import { booking_search_group } from "../../objects/v1_2/Booking_Details/booking_search_group";
import { Product } from '../../services/product';
import { Date_Service } from "../../../system/services/date_service";
import { Currency_Service } from "../../../system/services/currency";
import { booking_info } from '../../objects/v1_2/Booking_Details/booking_info';

const Prod: Product = new Product();
const Book: Booking = new Booking();
const Date_Services: Date_Service = new Date_Service();
const Currency_Services: Currency_Service = new Currency_Service();

export default defineComponent({
    components: {
        footer_item,
        header_menu,
        hero,
        basket,
        documentation,
        trip_summary
    },
    data() {
        return {
            gtag_purchased: false as Boolean
        }
    },
    methods: {
        async download_booking(): Promise<void> {
            const Book = new Booking();

            this.$store.commit("Setup_Booking", { Booking_Details: await Book.Get_Book(this.$store.getters.get_Booking?.group_booking_id, this.$store.getters.get_Agent_Login) });
            this.gtag_purchase();
        },
        getSubtitle(): string {
            return "Your booking reference number is <strong class='book-ref'>#" + this.$store.getters.get_Booking?.group_booking_id + "</strong>";
        },
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        getConfirmationText(): string {
            return String(import.meta.env.VITE_DEFAULT_CONFIRMATION_TEXT);
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        getFloDeskURL(): string {
            return String(import.meta.env.VITE_FLODESK_URL);
        },
        isValidView(): boolean {
            if(this.$store.getters.get_Booking?.group_booking_id == undefined || this.$store.getters.get_Booking?.group_booking_id == 0 || this.$store.getters.get_Booking?.group_booking_id == null) {
                return false;
            }
            return true;
        },
        home_Click() {
            this.$router.push('/');
        },
        
        get_Trips(): booking_info[]  {
            let Trips: booking_info[] = [];

            this.$store.getters.get_Booking?.trips?.forEach((Trip:booking_info) => {
                Trips.push(Trip);
            });

            return Trips;
        },
        passenger_info(passenger: client_booking): string {
            
            return Book.get_Passenger(passenger.passenger_identifier, this.$store.getters.get_Booking);
        },

        gtag_purchase() {
            if(!this.gtag_purchased)
            {
                this.gtag_purchased = true;
                var allItems: [] = [];
                let index = 0;
                let totalPrice = 0;
                let test = this.get_Trips().length;
                this.get_Trips().forEach(trip => {
                    let adult_count = 0;
                    let child_count = 0;
                    let senior_count = 0;
                    let student_count = 0;
                    let adult_price = 0;
                    let child_price = 0;
                    let senior_price = 0;
                    let student_price = 0;


                    trip.passengers.forEach(passenger => {
                        let ageCategory = Book.get_Passenger_Age_Cat(passenger?.passenger_identifier, this.$store.getters.get_Booking);
                        if (ageCategory.toLowerCase().includes("adult")) {
                            adult_count++;
                            adult_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (ageCategory.toLowerCase().includes("child")) {
                            child_count++;
                            child_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (ageCategory.toLowerCase().includes("senior")) {
                            senior_count++;
                            senior_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (ageCategory.toLowerCase().includes("student")) {
                            student_count++;
                            student_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                    });

                    var product_details = Prod.Get_Details_Sync(trip?.tour_id, this.$store);
                    if(adult_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, adult_count, adult_price, "adult"));
                    }
                    if(child_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, child_count, child_price, "child"));
                    }
                    if(senior_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, senior_count, senior_price, "senior"));
                    }
                    if(student_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, student_count, student_price, "student"));
                    }
                
                    index++;
                });

                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "purchase",
                    ecommerce: {
                        currency: this.$store.getters.get_Currency.currency_info.iso_currency_code,
                        value: totalPrice,
                        items: allItems
                    }
                });
            }
        },
        gtag_make_item(index: number, tour_id: string, tour_name: string, trip_index: string, paxCount: number, paxPrice: number, paxType: string) {

            let lengthCategory = "1 day tour";
            if(parseInt(Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store)).replace(" days", "").replace(" day", "")) > 1){
                lengthCategory = "Multi day tour";
            }

            var catOne = Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store));
            if(catOne.toLowerCase() == "1 day tour"){
                catOne = "AddOn";
            }

            var item = {
                item_id: tour_id,
                item_name: this.$store.getters.get_Booking?.trips[trip_index]?.tour_name,
                affiliation: import.meta.env.VITE_DEFAULT_TAB_TEXT + " Booking Engine",
                coupon: this.$store.getters.get_Booking?.trips[trip_index]?.promo_code,
                index: index,
                item_brand: import.meta.env.VITE_DEFAULT_TAB_TEXT,
                item_category: catOne,
                item_category2: lengthCategory,
                item_category3: Prod.product_start_location(Prod.Get_Details_Sync(tour_id, this.$store)),
                item_category4: paxType,
                item_list_name: lengthCategory,
                price: paxPrice,
                quantity: paxCount
            };

            return item;
        },
        GetBrand(): string {
             return String(import.meta.env.VITE_DEFAULT_CLASS);
        },
        get_conversion_purchase_tag() {
            return String(import.meta.env.VITE_DEFAULT_PURCHASE_TAG);
        },
        get_conversion_price(): string {
            return  String(Book.get_total_price(this.$store.getters.get_Booking, this.$store));
        },
        get_conversion_currency(): string {
            return this.$store.getters.get_Currency.currency_info.iso_currency_code;
        },
        get_conversion_transaction(): string {
            return this.$store.getters.get_Booking?.group_booking_id;
        }
    },
    mounted() {
        this.download_booking()
    }
})