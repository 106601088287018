﻿import { defineComponent } from 'vue';

export default defineComponent({
    components: {
    },
    data() {
        return {
            isActive: false,
            isActive_Agency: false
        }
    },
    methods: {
        get_Logo(): string {
            return String(import.meta.env.VITE_DEFAULT_LOGO);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        isConsumer_Login(): boolean {
            return import.meta.env.VITE_AGENT_IDENTITY_URL == undefined;
        },
        My_Profile() {
            this.$router.push('/my_profile')
        },
        Manage_Bookings() {
            this.$router.push('/manage_bookings')
        },
        Log_Out() {
            this.$store.commit("Logout_Agent");
            this.$router.push('/login');
        },
        Cart() {
            this.$router.push('/cart');
        },
        Logo_Click() {
            this.$router.push('/');
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
    }
})