﻿import { room } from "./room";

export const Room_Information: room = {
    'rooms': [
        {
            "passengers": 1,
            "room_types": [
                {
                    "name": "1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 2,
            "room_types": [
                {
                    "name": "1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Single Rooms",
                    "room_type_combinations": [
                        {                         
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }, 
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 3,
            "room_types": [
                {
                    "name": "1 Family 3 Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Twin Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 4,
            "room_types": [
                {
                    "name": "1 Family 4 Room",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Family 4",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "family 3 Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Twin Rooms",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Rooms",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Double Room, 1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        },
        {
            "passengers": 5,
            "room_types": [
                {
                    "name": "1 Family 4 Room, 1 Single Room",
                    "room_type_combinations": [
                        {
                            "occupants": 4,
                            "name": "Family 4",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Twin Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "1 Family 3 Room, 1 Double Room",
                    "room_type_combinations": [
                        {
                            "occupants": 3,
                            "name": "Family 3",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Twin Room, 1 Single",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Twin Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                },
                {
                    "name": "2 Double Room, 1 Single",
                    "room_type_combinations": [
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 2,
                            "name": "Double Room",
                            "passenger_links": []
                        },
                        {
                            "occupants": 1,
                            "name": "Single Room",
                            "passenger_links": []
                        }
                    ]
                }
            ]
        }
    ]
};