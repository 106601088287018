﻿<template>

    <header class="header">
        <header_menu></header_menu>
        <div class="hero" style="height:unset">
            <div class="hero-inner">
                <div class="hero-text">
                    <h1 class="title font-semibold login-title" >{{getLoginTitle()}}</h1>
                </div>

                <div class="login w-11/12 md:w-9/12 lg:w-6/12 xl:w-4/12">
                    <div :hidden="Hide_Login()"><agent_login></agent_login></div>
                    <div :hidden="Hide_Select_Consultant()"><select_consultant></select_consultant></div>
                    <div :hidden="screen != 'forgot_password'"><forgot_password_screen></forgot_password_screen></div>
                    <div :hidden="screen != 'register'"><register_screen></register_screen></div>
                </div>

            </div>

            <div class="hero-image">
                <img v-if="isAAT()" src="https://www.aatkings.com/assets/banner/kata-tjuta-viewing-platform-y8-banner.jpg" style="height: 1100px" />
                <img v-else :src=GetHeroImage() style="height: 1100px" />
            </div>
        </div>

    </header>

</template>

<script lang="ts" src="./login_screen.ts"></script>