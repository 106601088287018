﻿<template>
    

  <div v-if="displayPopup" class="z-50 bg-opacity-80 bg-black h-full w-full slideout-inner object-cover overflow-hidden overscroll-none fixed">
    <div class="z-50  p-2 h-full w-full rounded-md bg-white">
      <button class="close no-bg" @click="displayPopup=false"></button>
      <div v-if="!isMobile()" class="w-full text-center mb-8">
        <h3 class="mt-0">Tour Options</h3>
        <p class="text-center text-s">Please select brand and tour length</p>
      </div>
      <div v-else class="w-full text-center mb-8">
        <h3 class="-mt-10">Tour Options</h3>
        <p class="text-center text-s -my-5">Please select brand and tour length</p>
      </div>

      <div class="card  card-agent" :class="MobileCardClass()">
        <div class="card-inner card-haggis cursor-pointer haggis-card-inner bg-black "  @click="setfilter(1)">
          <a class="cover bg-black " ><img class="h-full w-full object-cover" src="https://contenthub.radicaltravel.com/media/1914/large-hag-outlander-trail-falkland-aprilroseadventure-codepodhostels-2022.jpg" alt="Scenic picture of available tour"></a>
          <div class="content text-center bg-black" :class="MobileTitleClass()">
            <h3><a class="cursor-pointer  cardTitle ">Haggis</a></h3>
            <h3><a class="cursor-pointer  cardTitle ">Day Tours</a></h3>
          </div>
        </div>
        <div class="card-haggis card-inner  cursor-pointer" @click="setfilter(2)">
          <a class="cover"><img class="h-full w-full object-cover" src="https://contenthub.radicaltravel.com/media/1047/h69skyehigh4-1.jpg?anchor=center&mode=crop&width=300&height=200" alt="Scenic picture of available tour"></a>
          <div class="content text-center bg-black" :class="MobileTitleClass()">
            <h3><a class="cursor-pointer  cardTitle ">Haggis</a></h3>
            <h3><a class="cursor-pointer  cardTitle ">2 to 8 day Tours</a></h3>
          </div> 
        </div>
        <div class="card-inner cursor-pointer" @click="setfilter(3)">
          <a class="cover"><img class="h-full w-full object-cover" src="https://contenthub.radicaltravel.com/media/2088/large-hag-kelpies-stephen-bridger-2016-5-copy-without-tower.jpg?anchor=center&mode=crop&width=300&height=200" alt="Scenic picture of available tour"></a>
          <div class="content text-center" :class="MobileTitleClass()">
            <h3><a class="cursor-pointer  cardTitle ">Highland Explorer</a></h3>
            <h3><a class="cursor-pointer  cardTitle ">Day Tours</a></h3>
          </div>
        </div>
        <div class="card-inner cursor-pointer" @click="setfilter(4)">
          <a class="cover" ><img class="h-full w-full object-cover" src="https://contenthub.radicaltravel.com/media/2021/large-hex-callanish-stones-michelle-morgan-2017.jpg?anchor=center&mode=crop&width=300&height=200" alt="Scenic picture of available tour"></a>
          <div class="content text-center" :class="MobileTitleClass()">
            <h3><a class="cursor-pointer  cardTitle ">Highland Explorer</a></h3>
            <h3><a class="cursor-pointer  cardTitle ">2 to 8 day Tours</a></h3>
          </div>        </div>
      </div>
      <div class="w-full text-center mb-8">
        <div><button type="button" class="button button-pink-book" :class="MobileButtonClass()" @click="displayPopup=false">Show all tours</button></div>
      </div>


    </div>
  </div>

  

   
</template>

<script lang="ts" src="./agent_options.ts"></script>