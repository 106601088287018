﻿<template>

    <!-- Trip Filter -->
    <div class="overlay-title">
        <svg class="inline w-4 h-4 svg-inline--fa fa-sliders-h fa-w-16 fa-2x" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sliders-h" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M504 384H192v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h312c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-344 64h-32v-96h32v96zM504 96H256V56c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h152v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h248c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-280 64h-32V64h32v96zm280 80h-88v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h312v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-120 64h-32v-96h32v96z" class=""></path></svg>
        <strong>Trip Filters</strong>
    </div>
    <div class="w-full md:w-2/12" v-for="filter in getFilters()" :key="filter.name">
        <div id="Filter_Click_Away">
            <button class=" select-button" @click="toggleFilter(filter)" :class="{'active': filter.isOpen}">
                <small v-if="filter.name == 'Departing'">Departure Point</small>
                <small v-else>{{ filter.name }}</small>
                <strong>{{ filter.selectedTag.name}}</strong>
            </button>
            <div class="select-dropdown" v-click-away="onClickAway" v-if="filter.isOpen">
                <ul>
                    <li v-for="tag in FilterTags(filter.tags)" :key="tag.tag_id"
                        :class="[ tag.tag_id === filter.selectedTag.tag_id ? 'selected' : '' ]"
                        @click="selectFilterTag(filter,tag)">
                        <div>{{tag.name}}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script lang="ts" src="./filter_list.ts"></script>