﻿import { defineComponent, PropType } from 'vue';

import { product_detail } from '../../../objects/v1_3/product/product/product_detail';

export default defineComponent({
    name: "tour_heading",
    props: {
        product: Object as PropType<product_detail>
    },
    methods: {
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        ValidateDates(dates): boolean {
            var currentTime = new Date()
            var returnValue = true;

            dates.forEach(dateRange => {
                if ((dateRange.date_from == null || dateRange.date_from == "") && (dateRange.date_to == null || dateRange.date_to == "")) {
                    return true;
                }
                let date_from = new Date(dateRange.date_from);
                let date_to = new Date(dateRange.date_to);
                if (date_from > currentTime || date_to < currentTime) {
                    returnValue = false;
                }
            });

            return returnValue;
        },
        imageAltText(): string {
            return this.product?.marketing_information?.media[0]?.assets[0]?.name ?? "Image of " +  this.product?.name;
        },
        GetBookingNotes(): string {
            let noteMessage: string = "";
            let bookinging_notes = this.product?.operational_information?.booking_notes;
            let limit: number = 0;
            if (bookinging_notes != null && bookinging_notes.length > 0) {

                bookinging_notes.forEach(booking_note => {
                    if(booking_note.display_calendar == true && this.ValidateDates(booking_note.dates)){
                        //strips HTML tags due to rich text formatting
                        let div = document.createElement("div");
                        div.innerHTML = booking_note.notes;
                        if(limit + div.innerHTML.length < 500){
                            limit += div.innerHTML.length;
                            noteMessage += div.textContent || div.innerText || "";
                        }
                    }
                });
            }
            return noteMessage;
        },
        gtag_view_item() {
            if(this.product?.tour_id != null && this.product?.tour_id != "")
            {
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: this.$store.getters.get_Currency.currency_info.iso_currency_code,
                    items: [
                        {
                            item_id: this.product?.tour_id,
                            item_name: this.product?.seller_information?.sales_group?.group_name,
                            affiliation: import.meta.env.VITE_DEFAULT_TAB_TEXT + " Booking Engine",
                            item_brand: import.meta.env.VITE_DEFAULT_TAB_TEXT,
                        }
                    ]
                }
                });
            }
        }
    },
    mounted() {
    }
})