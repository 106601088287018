﻿import { defineComponent, PropType } from 'vue';

import { week_day } from "../../../../objects/front_end/calendar/week_day";

import { Booking } from "../../../../services/booking";
import { Currency_Service } from "../../../../../system/services/currency";

const Book = new Booking();
const Currency_Services = new Currency_Service();

export default defineComponent({
    name: "calendar_day",
    props: {
        day: Object as PropType<week_day>,
        selected_tour_id: String,
        trip_index: Number
    },
    data() {
        return {
            almostFull: false as boolean
        }
    },
    emits: [
        "dateChange"
    ],
    methods: {
        getDay(): string {
            if (this.day == undefined) {
                return "";
            }

            if (this.day.totalCapacity == -1) {
                return "";
            }

            return String(this.day.number);
        },
        getSeats(): string {
            if (this.day == undefined) {
                return "";
            }

            if (this.day.totalCapacity == -1) {
                return "";
            }

            if (this.isFull() == true) {
                return "";
            }

            return ((this.day.totalCapacity > 5) ? "5 +" : String(this.day.totalCapacity)) + " left";
        },
        isToday(): boolean {
            if(this.day?.is_today == true)
            {
                return true;
            }
            else
            {
                return false;
            }
        },
        getPrice(): string {
            if (this.day == undefined) {
                return "";
            }

            if (this.day.totalCapacity == -1) {
                return "";
            }

            if (this.day.fromPrice == -1) {
                return "";
            }

            return Currency_Services.Format_Currency(this.day.fromPrice, this.$store.getters.get_Currency);
        },
        async date_selected() {
            if (this.day == undefined) {
                return;
            }

            if (this.day.totalCapacity == -1) {
                return;
            }

            if (this.isFull() == true) {
                return;
            }

            this.$emit("dateChange", this.day);

            this.$store.commit("Initialise_Basket_Departure", { Trip_Index: this.get_Trip_Index(), Departure_Date: this.day.date, Capacity: this.day.capacity_info });
            await Book.Setup_Booking(this.$store);
        },
        isDate_Selected(): boolean {
            let Basket = this.$store.getters.get_Basket;

            let queryDate = this.GetDepartureDateFromQuery();
            let calendardate = this.day?.date;
            let isQueryDateCalendarDate : boolean = (queryDate == calendardate);
            let isDateSelectedInBasket : boolean = false;
            if (this.get_Trip_Index() > -1 && Basket.trips[this.get_Trip_Index()] != undefined) {
                isDateSelectedInBasket= (Basket.trips[this.get_Trip_Index()].departure_date != null && Basket.trips[this.get_Trip_Index()].departure_date != "");
            }

            if (Basket.trips.length > this.get_Trip_Index())
            {
                if (Basket.trips[this.get_Trip_Index()].departure_date == this.day?.date) {
                    return true;
                }
                else if (isQueryDateCalendarDate && !isDateSelectedInBasket)
                {
                    this.date_selected();
                    return true;
                }
            }

            return false;
        },
        isFull(): boolean {
            if ((this.day?.totalCapacity ?? 0) == -1) {
                return false;
            }

            if (this.day?.tour_id.findIndex((f: string) => f == this.selected_tour_id) == -1) {
                return true;
            }
            if ((this.day?.totalCapacity ?? 0) <= 4) {
                this.almostFull = true;
            }

            return ((this.day?.totalCapacity ?? 0) == 0);
        },
        get_Trip_Index(): number {
            return this.trip_index ?? 0;
        },
        GetDepartureDateFromQuery() : string {
            let url = window.location.search.substring(1).replace("%20"," ");
            let params = url.split("&");
            let depDate = "";

            if(params.length > 0)
            {
                params.forEach((param: string) => {
                    if(param.includes("departuredate"))
                    {
                        depDate = param.split("=")[1];
                    }  
                });
            }

            return depDate;
        }
    },
    mounted() {
    }
})