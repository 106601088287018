﻿import { defineComponent, PropType } from 'vue';

import trip_summary from "../common/trip_summary.vue"
import trip_summary_discount from "../common/trip_summary_discount.vue"
import itinerary_breakdown from "../common/itinerary_breakdown.vue";
import { booking_info } from '../../../objects/v1_2/Booking_Details/booking_info';
import { client_booking } from '../../../objects/v1_2/Booking_Details/client_booking';

import { Booking } from "../../../services/booking";

import { Currency_Service } from "../../../../system/services/currency";
import { booking_group } from "../../../objects/v1_2/Booking/Booking_Group"
import addOns from './add-ons';
import tour from './tour';
import { Product } from '../../../services/product';
import { product_search } from "../../../objects/v1_3/product/search/product_search";
import { trip } from "../../../objects/v1_2/Booking/Trip";
import { product_search_product } from "../../../objects/v1_3/product/search/product_search_product";

const Book: Booking = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();
const Prod = new Product();
class GooglePassengerDetail {
    public age_cat: string = "";
    public count: number = 0;
    public price: number = 0;
    public tourIndex: number = 0;
}

export default defineComponent({
    name: "trip_sum",
    components: {
        trip_summary,
        trip_summary_discount,
        itinerary_breakdown
    },
    props: {
    },
    data() {
        return {
            checkout: "Continue" as string,
            booking_now: false as Boolean,
            agreeCheckbox: false as Boolean,
            popedUp: false as Boolean,
            displayPopup: false as Boolean,
            bookings: [] as booking_group[],
            trips: this.$store.getters.get_Booking?.trips as trip[],
            product_search: new product_search() as product_search,
            addon_list: [] as string[],
            popup_images: [] as string[],
            gtag_checkout_began: false as Boolean,
        }
    },
    methods: {
        async Download_Add_Ons(trip: trip) {
            
            const Prod = new Product();
            let Store = this.$store;

            this.product_search = await Prod.Get_Sales_AddOns(trip?.tour_id ?? ""); 
            this.product_search?.products?.forEach(async function (product: product_search_product) {
                await Prod.Get_Details(product.tour_id, Store);
            });
            this.get_Add_Ons();
        },
        async check_out() {
            this.checkout = "Booking Now";
            this.booking_now = true;

            let booking_info = await Book.Book_Trip(this.$store).then(response => { return response.json(); });

            if (booking_info.group_booking_id > 0) {
                this.$store.commit("Setup_Booking", { Booking_Details: booking_info });
                this.$store.commit("Initialise_Basket", { Currency: "", Trip_Index: 0, Tour_ID: "" });
                this.gtag_begin_checkout();
                this.$router.push({ path: "/payment", hash: "#passenger_" + booking_info.passengers[0].passenger_identifier});
            } else {
                this.$store.getters.get_Basket.trips.forEach(trip => {
                    if(trip.rooms == null || trip.rooms.length == 0 || trip.rooms == undefined){
                        booking_info.booking_error = "Error: No rooms available or incorrectly loaded rooms."
                    }
                });
                alert("Summary " + booking_info.booking_error);
            }

            this.booking_now = false;
            this.checkout = "Checkout Now";
        },
        get_Trips(): booking_info[]  {
            let Trips: booking_info[] = [];

            this.$store.getters.get_Booking?.trips?.forEach((Trip:booking_info) => {
                Trips.push(Trip);
            });

            return Trips;
        },
        passenger_info(passenger: client_booking): string {
            return Book.get_Passenger(passenger.passenger_identifier, this.$store.getters.get_Booking);
        },

        gtag_begin_checkout() {
            if(!this.gtag_checkout_began)
            {
                this.gtag_checkout_began = true;
                var allItems: [] = [];
                let index = 0;
                let totalPrice = 0;

                this.get_Trips().forEach(trip => {
                    let adult_count = 0;
                    let child_count = 0;
                    let senior_count = 0;
                    let student_count = 0;
                    let adult_price = 0;
                    let child_price = 0;
                    let senior_price = 0;
                    let student_price = 0;

                    trip.passengers.forEach(passenger => {
                        if (this.passenger_info(passenger).toLowerCase().includes("adult")) {
                            adult_count++;
                            adult_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (this.passenger_info(passenger).toLowerCase().includes("child")) {
                            child_count++;
                            child_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (this.passenger_info(passenger).toLowerCase().includes("senior")) {
                            senior_count++;
                            senior_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                        else if (this.passenger_info(passenger).toLowerCase().includes("student")) {
                            student_count++;
                            student_price += passenger.price;
                            totalPrice += passenger.price;
                        }
                    });

                    var product_details = Prod.Get_Details_Sync(trip?.tour_id, this.$store);
                    if(adult_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, adult_count, adult_price, "adult"));
                    }
                    if(child_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, child_count, child_price, "child"));
                    }
                    if(senior_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, senior_count, senior_price, "senior"));
                    }
                    if(student_count > 0)
                    {
                        allItems.push(this.gtag_make_item(index, product_details.tour_id, product_details.name, index, student_count, student_price, "student"));
                    }
                
                    index++;
                });

                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                        currency: this.$store.getters.get_Currency.currency_info.iso_currency_code,
                        value: totalPrice,
                        items: allItems
                    }
                });
            }
            
        },
        gtag_make_item(index: number, tour_id: string, tour_name: string, trip_index: string, paxCount: number, paxPrice: number, paxType: string) {

            let lengthCategory = "1 day tour";
            if(parseInt(Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store)).replace(" days", "").replace(" day", "")) > 1){
                lengthCategory = "Multi day tour";
            }

            var catOne = Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store));
            if(catOne.toLowerCase() == "1 day tour"){
                catOne = "AddOn";
            }

            var item = {
                item_id: tour_id,
                item_name: this.$store.getters.get_Booking?.trips[trip_index]?.tour_name,
                affiliation: import.meta.env.VITE_DEFAULT_TAB_TEXT + " Booking Engine",
                coupon: this.$store.getters.get_Booking?.trips[trip_index]?.promo_code,
                index: index,
                item_brand: import.meta.env.VITE_DEFAULT_TAB_TEXT,
                item_category: catOne,
                item_category2: lengthCategory,
                item_category3: Prod.product_start_location(Prod.Get_Details_Sync(tour_id, this.$store)),
                item_category4: paxType,
                item_list_name: lengthCategory,
                price: paxPrice,
                quantity: paxCount
            };

            return item;
        },
        get_Add_Ons() {
            const Book = new Booking();
            let Booking_Info = this.$store.getters.get_Booking;

            let Output: string[] = [];
            let addon_images: string[] = [];

            this.product_search?.products?.forEach(async function (product: product_search_product) {
                if (Book.Trip_Selected(product.tour_id, Booking_Info) == false) {

                    Output.push(product.name);
                    addon_images.push(product.name + "|" + product.media_asset.asset_url + "?anchor=center&mode=crop&width=600");

                }
            });

            
            this.addon_list.push(...Output);
            this.popup_images.push(...addon_images);

        },
        get_popup_text(): string {
            if(this.filter_addons(this.addon_list).length == 1)
            {
                return "Last chance to secure your spot for " + this.filter_addons(this.addon_list)[0] + "!";
            }
            else
            {
                return "Last chance to secure your spot for " + this.filter_addons(this.addon_list)[0] + " and other add-ons!";
            }
        },
        get_popup_image(): string {
            let Output: string = "";
            this.filter_addons(this.popup_images).forEach(image => {
                if(image.includes(this.filter_addons(this.addon_list)[0]))
                {
                    Output = image.split("|")[1];
                }
            });


            return Output;
        },
        get_Decimal_Total(): number{
            return Book.get_total_price(this.$store.getters.get_Booking, this.$store)
        },
        get_Total(): string {
            return Currency_Services.Format_Currency(Book.get_total_price(this.$store.getters.get_Booking, this.$store) + Book.get_add_ons(this.$store), this.$store.getters.get_Currency);
        },
        get_Currency(): string {
            return this.$store.getters.get_Currency?.currency_info?.iso_currency_code;
        },
        get_Contact_Text(): string {
            return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        filter_addons(list: string[]): string[] {
            list.forEach(addon => {
                if(addon.toUpperCase().includes(import.meta.env.VITE_DEFAULT_ECO_NAME.toUpperCase()))
                {
                    list.splice(list.indexOf(addon), 1);
                }
            });

            return list;

        },
        addons_Check(){
            if(!this.isAgent_Login() && Book.getTotal_Add_Ons(this.$store.getters.get_Basket) <= 0 && !this.popedUp && this.filter_addons(this.addon_list).length > 0)
            {
                this.popedUp = true;
                this.displayPopup = true;
                window.scrollTo(0, 0);

            }
            else
            {
                this.check_out();
            }
        },
        show_addons(){
            this.displayPopup = false;

        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },
    mounted() {
        this.trips.forEach(trip => {
            this.Download_Add_Ons(trip);
        });
        if (this.$store.getters.get_Agent_Login?.valid_login == true) {
            this.agreeCheckbox = true;
        }
    }
})