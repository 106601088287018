﻿<template>

    <header v-if="!EmbedCheck()" class="header">
        <header_menu></header_menu>
        <hero :title="getSearchTitle()" :subtitle="getSearchHeader()" :hero_image="getHeroImage()"></hero>
    </header>

    <main class="main w-full h-42 ">

        <searchfilter v-if="!EmbedCheck()" v-on:onFilterChange="onFilterChange" v-on:onTagChange="onTagChanges" v-on:onSearchChange="onSearchChanges" @tourSelected="Tour_Selected"></searchfilter>

        <div v-if="!EmbedCheck()"  id="product" class="row searchBackground">
            <div class="flex" :hidden="loading_search">
                <!--TRAIN TEMPORARY HACK PART-->
                <div v-if="isTrain" >
                    <h2  @click="isHidden = false" class="flex-0 text-2xl md:mx-4 mb-0" :hidden="product_search?.products?.length == 0">2 times are available for this tour</h2>
                    <a @click="GoHome()" class="hover:no-underline rounded-full my-2 my-2 mx-0 md:mx-2 px-3 py-1 text-sm button button-bluelight">See All Tours</a>
                </div>
                <h2 v-else @click="isHidden = false" class="flex-0 text-2xl md:mx-4 mb-0" :hidden="product_search?.products?.length == 0">{{includedTours(product_search?.products).length}} Tours Available</h2>
                <h2 v-if="!loading_search" class="flex-0 text-2xl md:mx-4 mb-0" :hidden="product_search?.products?.length != 0">Sorry none of our tours meet your search criteria</h2>
                <filter_buttons v-on:onTagChange="onTagChanges"></filter_buttons>
            </div>
            <div :hidden="!loading_search">
                <button type="button" class="button button-pink-book full">
                    <svg class="animate-spin h-5 w-5 mr-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                        <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
                    </svg>
                    {{getLoadingText()}}
                </button>
            </div>
            <div class="flex flex-wrap">
                <div class="card" v-if="CartCount() > 0" v-for="tour_item in in_cart.products">
                    <tour_card :InCart="true" :OpenBooking=URLCheck(tour_item.tour_id) :tour_details=tour_item ></tour_card>
                </div>
                <div v-if="!isTrain" class="card" v-for="tour_item in includedTours(product_search?.products)">
                        <tour_card :OpenBooking=URLCheck(tour_item.tour_id) :tour_details=tour_item @bookTour="onBookTour"></tour_card>
                </div>
                <!--TRAIN TEMPORARY HACK PART-->
                <div v-else class="card" v-for="tour_item in GetTrainTours(product_search?.products)">
                        <tour_card :OpenBooking=URLCheck(tour_item.tour_id) :tour_details=tour_item @bookTour="onBookTour"></tour_card>
                </div>

            </div>
            <div class="slideout" :hidden="isHidden" :active="!isHidden"
                 x-transition:enter="transition ease-out duration-500 -mr-64"
                 x-transition:enter-start=""
                 x-transition:enter-end="transform -translate-x-64"
                 x-transition:leave="transform ease-out duration-75"
                 x-transition:leave-start=""
                 x-transition:leave-end="transform translate-x-64"
                 style="z-index: 201 !important;">
                <div class="slideout-inner">
                    <button class="close no-bg" @click="Reset_Basket()"></button>

                    <div class="slideout-content">
                        <tour_details :trip_index=trip_index ref="tour_details" @Add_To_Cart="Add_To_Cart"></tour_details>
                    </div>

                </div>

                <div class="slideout-bg" @click="Reset_Basket()"></div>

            </div>
        </div>

        <!--when embeded in url--->
        <div v-else id="product" class="row searchBackground">
            <div class="flex flex-wrap">
                <div class="card" >
                    <tour_card v-if="EmbededTour(includedTours(product_search?.products)).tour_id != 'NUL'" :OpenBooking=URLCheck(EmbededTour(includedTours(product_search?.products)).tour_id) :tour_details=EmbededTour(includedTours(product_search?.products)) @bookTour="onBookTour"></tour_card>
                </div>
            </div>
            <div class="slideout " :hidden="isHidden" :active="!isHidden"
                 x-transition:enter="transition ease-out duration-500 -mr-64"
                 x-transition:enter-start=""
                 x-transition:enter-end="transform -translate-x-64"
                 x-transition:leave="transform ease-out duration-75"
                 x-transition:leave-start=""
                 x-transition:leave-end="transform translate-x-64"
                 style="z-index: 201 !important;">
               
                <div >
                    <div class="slideout-content embed">
                        <embedded_calendar :trip_index=trip_index ref="tour_details" @Add_To_Cart="Add_To_Cart"></embedded_calendar>
                    </div>

                </div>

                <div class="slideout-bg" @click="Reset_Basket()"></div>

            </div>
        </div>
    </main>

    <footer_item v-if="!EmbedCheck()"></footer_item>

</template>

<script lang="ts" src="./1_search.ts"></script>