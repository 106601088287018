﻿import { defineComponent } from 'vue';

import passenger_details from "../../base/passenger_details.vue"
import tour_details from "../../base/2_tour_details/tour_details.vue"
import basket_item from "./basket_item.vue"

import itinerary from "./itinerary.vue";

import { booking_search_group } from "../../../objects/v1_2/Booking_Details/booking_search_group"
import { age_cat } from "../../../objects/v1_3/web_engine/age_cat"

import { Product } from '../../../services/product';
import { Booking } from '../../../services/booking';
import { Web_Engine } from '../../../services/web_engine';

import { Date_Service } from '../../../../system/services/date_service';
import { Currency_Service } from '../../../../system/services/currency';

const Book: Booking = new Booking();
const Prod: Product = new Product();

const Date_Services: Date_Service = new Date_Service();
const Currency_Services: Currency_Service = new Currency_Service();

import { product_detail } from '../../../objects/v1_3/product/product/product_detail';
import { booking_info } from '../../../objects/v1_2/Booking_Details/booking_info';
import { trip } from '../../../objects/v1_2/Booking/Trip';

class GooglePassengerDetail {
    public age_cat: string = "";
    public count: number = 0;
    public price: number = 0;
}

export default defineComponent({
    name: "basket",
    components: {
        passenger_details,
        itinerary,
        tour_details,
        basket_item
    },
    props: {
    },
    data() {
        return {
            isHidden: true as boolean,
            isHidden_Edit_Tour: true as boolean,
            tour_id: "" as string,
            age_cat: new age_cat() as age_cat,
            deletedTours: [] as string[]
        }
    },
    methods: {
         async download_passenger() {
            const web_engine = new Web_Engine();
            this.age_cat = await web_engine.Get_Passengers();
            this.age_cat.categories = this.age_cat.categories.sort((first, second): number => {
                return 0 - (first.name > second.name ? -1 : 1)
            });
        },
        add_on_tour(trip: booking_info): boolean {
            return Book.Add_On(trip, this.$store);
        },
        get_Tour_Details(tour_id: string): product_detail {
            return Prod.Get_Details_Sync(tour_id, this.$store);
        },
        duration(tour_id: string): string {
            return Prod.product_duration(Prod.Get_Details_Sync(tour_id, this.$store));
        },
        durationInDays(tour_id: string): string {
            return Prod.product_durationInDays(Prod.Get_Details_Sync(tour_id, this.$store));
        },
        trip_starts(departure_date: string, tour_id: string) {
            return Prod.trip_starts(departure_date, Prod.Get_Details_Sync(tour_id, this.$store));
        },
        trip_ends(departure_date: string, tour_id: string) {
            return Prod.trip_ends(departure_date, Prod.Get_Details_Sync(tour_id, this.$store));
        },
        trip_checkintime(tour_id: string) {
            return Prod.trip_checkintime(Prod.Get_Details_Sync(tour_id, this.$store));
        },
        start_location(tour_id: string) {
            return Prod.product_start_location(Prod.Get_Details_Sync(tour_id, this.$store));
        },
        end_location(tour_id: string) {
            return Prod.product_end_location(Prod.Get_Details_Sync(tour_id, this.$store));
        },
        get_Image(tour_id: string, width: number, height: number) {
            return Prod.get_image(Prod.Get_Details_Sync(tour_id, this.$store), width, height);
        },
        imageAltText(tour_id: string): string {
            return Prod.Get_Details_Sync(tour_id, this.$store).marketing_information?.media[0]?.assets[0]?.name ?? "Image of " + Prod.Get_Details_Sync(tour_id, this.$store).name;
        },
        get_price(trip: booking_info): string {
            return Currency_Services.Format_Currency(Book.get_price(trip), this.$store.getters.get_Currency);
        },
        get_Currency(): string {
            return this.$store.getters.get_Currency?.currency_info?.iso_currency_code;
        },
        valid_Times(timeString: string)
        {
            if(timeString.includes('NaN') || timeString.includes('undefined'))
            {
                return false;
            }
            else
            {
                return true;
            }
        },
        async Remove_Trip(tour_id: string) {
            this.deletedTours.push(tour_id);
            this.$store.commit("Remove_Product", { Tour_ID: tour_id });
            this.gtag_remove_from_cart(tour_id);

            if (this.$store.getters.get_Basket?.trips[0]?.tour_id == "" || this.$store.getters.get_Basket.trips.length == 0) {
                this.$router.push("/");
            } else {
                this.$store.commit("Setup_Booking", { Booking_Details: await Book.Quote_Booking(this.$store) });
                this.$router.push({ path: "/cart", hash: "#add_on_" + tour_id });
            }
        },
        gtag_get_item(index: number, paxType: string, paxCount: number, paxPrice: number, trip) {

           
            let lengthCategory = "1 day tour";
            if(parseInt(Prod.product_durationInDays(Prod.Get_Details_Sync(this.tour_id, this.$store)).replace(" days", "").replace(" day", "")) > 1){
                lengthCategory = "Multi day tour";
            }

            var item = {
                item_id: trip.tour_id,
                item_name: trip?.tour_name,
                affiliation: import.meta.env.VITE_DEFAULT_TAB_TEXT + " Booking Engine",
                coupon: trip?.promo_code,
                index: index,
                item_brand: import.meta.env.VITE_DEFAULT_TAB_TEXT,
                item_category: Prod.product_durationInDays(Prod.Get_Details_Sync(trip.tour_id, this.$store)),
                item_category2: lengthCategory,
                item_category3: Prod.product_start_location(Prod.Get_Details_Sync(trip.tour_id, this.$store)),
                item_category4: paxType,
                item_list_name: lengthCategory,
                price: paxPrice,
                quantity: paxCount
            };

            return item;
        },
        
        gtag_remove_from_cart(tour_id: string) {

            var allItems: [] = [];
            var index = 0;
            var totalAmount = 0;
            var GooglePassengerDetails: GooglePassengerDetail[] = [];



            var trip = this.$store.getters.get_Booking?.trips[0];

            this.$store.getters.get_Booking?.trips.forEach(currenttrip => {
                if(currenttrip.tour_id == tour_id){
                    trip = currenttrip;
                }
            });
            

            trip.passengers.forEach(pax => {
                let passengerAge = Book.get_Passenger_Age_Cat(pax.passenger_identifier, this.$store.getters.get_Booking);
                totalAmount += pax.price;

                if(GooglePassengerDetails.findIndex(f => f.age_cat == passengerAge) == -1){
                    GooglePassengerDetails.push({age_cat: passengerAge, count: 1, price: pax.price});
                }
                else{
                    let index_location = GooglePassengerDetails.findIndex(f => f.age_cat == passengerAge);
                    GooglePassengerDetails[index_location].count++;
                    GooglePassengerDetails[index_location].price = pax.price;
                }

            });

            GooglePassengerDetails.forEach(pax => {
                allItems.push(this.gtag_get_item(index, pax.age_cat, pax.count, pax.price, trip));
                index++;
            });


            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
                event: "remove_from_cart",
                ecommerce: {
                    currency: this.$store.getters.get_Currency,
                    value: totalAmount,
                    items: allItems
                }
            });
        },
        deletedTour(tour_id: string): boolean {
            return this.deletedTours.includes(tour_id);
        },
        tour_selected(tour_id: string) {
            this.tour_id = tour_id;
            this.isHidden = false;

            this.$refs.itinerary.download_product(tour_id);
        },
        tour_edit(tour_id: string) {
            this.tour_id = tour_id;
            this.$refs.tour_details.download_product(tour_id);

            this.isHidden_Edit_Tour = false;
        },
        Add_To_Cart() {
            this.isHidden_Edit_Tour = true;
        },
        getPassenger_Details(tour_id: string): string {
            return Book.Passenger_Details(false, this.age_cat, tour_id, this.$store.getters.get_Booking);
        },
        passenger_total(tour_id: string): string {
            if (this.$store.getters.get_Booking?.passengers?.length == null || this.$store.getters.get_Booking?.passengers?.length == 0) {
                return "";
            }

            if (this.$store.getters.get_Booking?.passengers?.length == 1) {
                return "1 guest";
            }

            return this.$store.getters.get_Booking?.passengers?.length + " guests";
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        FormatProductName(product_name: string): string {
            return product_name.split(' (')[0];
        },
        FormatDescription(description: string): string {
            if(description.length > 200)
            {
                return description.substring(0, 180) + "...";
            }
            return description;
        },
        zeroPriceCheck(trip: booking_info): boolean {
            return Book.get_price(trip) != 0;

        },
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        }
    },
    mounted() {
        this.download_passenger();
    }
})