﻿import { defineComponent } from 'vue';

import { request_register } from "../../booking_engine/objects/agent/identity/v1_3/request_register"
import { response_register } from "../../booking_engine/objects/agent/identity/v1_3/response_register"

import { Agent_Login } from "../../booking_engine/services/agent_login";

let Login: Agent_Login = new Agent_Login();

export default defineComponent({
    components: {
    },
    props: {
    },
    data() {
        return {
            Register_Request: new request_register()
        }
    },
    methods: {
        async Register() {
            let Response_Register: response_register = await Login.Register(this.Register_Request);
            if (Response_Register.message_sent == false) {
                window.alert("Failed to register " + Response_Register.message);
            } else {
                window.alert("Sucessfully registered the agency.");
            }
        },
        Forgot_Password() {
            this.$router.push('/login/forgot_password');
        },
        Login() {
            this.$router.push('/login');
        },
        getContactInfo(): string {
            return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
        },
        getLoginTitle(): string {
            return String(import.meta.env.VITE_DEFAULT_LOGIN_TITLE);
        },
        isMobile(): boolean{
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        isAAT(): boolean {
            if (import.meta.env.VITE_DEFAULT_PARENT == "AAT") {
                return true;
            }
            return false;
        },
        getMobileClass(): string {
            if(this.isMobile() && !this.isAAT()){
                return "RTGFormMobile"
            }
            return "grid grid-cols-2 items-center"
        }
    },
    mounted() {
    }
});