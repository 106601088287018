﻿<template>

    <div class="w-full text-center mb-8">
        <h3 class="uppercase mt-0">REGISTRATION</h3>
        <p class="text-center text-xs">Please complete the registration form to register for {{getLoginTitle()}}</p>
        <p class="text-center text-xs">You will receive an email containing you username and password.</p>
    </div>

    <div :class="getMobileClass() ">
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">Name:*</label>
        <input type="text" id="name" name="Name" placeholder="Name" class="flex-1 py-2 border-black" v-model="Register_Request.name" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">Agency Name:*</label>
        <input type="text" id="agency_name" name="agency_name" placeholder="Agency Name" class="flex-1 py-2 border-black" v-model="Register_Request.agency_name" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">Agency Phone Number:*</label>
        <input type="text" id="agency_phone_number" name="name" placeholder="Agency Phone Number" class="flex-1 py-2 border-black" v-model="Register_Request.agency_phone_number" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">Agency Address:*</label>
        <input type="text" id="agency_address" name="agency_address" placeholder="Agency Address" class="flex-1 py-2 border-black" v-model="Register_Request.agency_address_line_1" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold"></label>
        <input type="text" id="agency_address_2" name="agency_address_2" placeholder="Address" class="flex-1 py-2 border-black" v-model="Register_Request.agency_address_line_2" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">Agency Email Address:*</label>
        <input type="text" id="gency_email_address" name="agency_email_address" placeholder="Agency Email Address" class="flex-1 py-2 border-black" v-model="Register_Request.agency_email" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">IATA:</label>
        <input type="text" id="iata" name="iata" placeholder="IATA" class="flex-1 py-2 border-black" v-model="Register_Request.iata" />
        <label for="name" class="inline-block w-30 mr-6 text-right font-bold">ABN:</label>
        <input type="text" id="abn" name="abn" placeholder="ABN" class="flex-1 py-2 border-black" v-model="Register_Request.abn" />
    </div>

    <p class="text-center"><div @click="Register()" class="button button-default w-full sm:w-3/4 rounded-full">Submit</div></p>
    <p class="text-center text-xs">{{getContactInfo()}}</p>

    <div class="grid grid-cols-2">
        <div class="anchor text-sm textButton" @click="Login()">Login</div>
        <div class="text-right text-sm textButton"><div @click="Forgot_Password()">Forgot Password</div></div>
    </div>

</template>

<script lang="ts" src="./register_screen.ts"></script>